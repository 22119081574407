.relatedproducts{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    height: 90vh;
    margin-bottom: 170px;
}
.relatedproducts>h1{
    color: #171717;
    font-size: 50px;
    font-weight: 600;
}
.relatedproducts>hr{
    width: 200px;
    height: 0.5px;
    border-radius: 10px;
    border: 2.5px solid #252525;
    background: #252525;
}
.relatedproducts-items{
    margin-top: 50px;
    display: flex;
    gap: 30px;
}