.items{
    width: 350px;
}
.item-image{
    width: 350px;
    height: 500px;
    object-fit: contain;
}
.items>p{
    margin: 6px 0px;
}
.items-prices{
    display: flex;
    gap: 20px;

}
.item-price-new{
    color: #374151;
    font-size: 18px;
    font-weight: 600;
}
.item-price-old{
    color: #374151;
    font-size: 18px;
    font-weight: 500;
    text-decoration: line-through;
}

.items:hover{
    transform: scale(1.05);
    transition: 0.6s;
}