.productdisplay{
    display: flex;
    margin: 0 170px;
    align-items: center;
}
.productdisplay-left{
    display: flex;
    gap: 17px;
}
.productdisplay-img-list{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.productdisplay-img-list>img{
    height: 163px;
}
.productdisplay-main-img{
    width: 586px;
    height: 700px;
}

.productdisplay-right{
    margin: 0 70px;
    display: flex;
    flex-direction: column;
}

.productdisplay-right>h1{
    color: #3d3d3d;
    font-size: 40px;
    font-weight: 700;
}
.productdisplay-star{
    display: flex;
    align-items: center;
    margin-top: 13px;
    gap: 5px;
    color: #1c1c1c;
    font-size: 16px;
}
.productdisplay-star>img{
    width: 20px;
}
.productdisplay-prices{
    display: flex;
    margin: 40px 0;
    gap: 30px;
    font-size: 24px;
    font-weight: 700;
}
.productdisplay-old-price{
    color: #818181;
    text-decoration: line-through;
}
.productdisplay-new-price{
    color: #ff4141;
}
.productdisplay-size>h1{
    margin-top: 55px;
    color: #656565;
    font-size: 20px;
    font-weight: 600;
}
.productdisplay-sizechart{
    display: flex;
    margin: 30px 0;
    gap: 20px;
}
.productdisplay-sizechart>div{
    padding: 18px 24px;
    background: #fbfbfb;
    border:1px solid #ebebeb;
    border-radius: 3px;
    cursor: pointer;
}
.productdisplay-right>button{
    padding: 20px 40px;
    width: 200px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: #ff4141;
    margin-bottom: 40px;
    border-radius: 3px;
    border: none;
    outline: none;
    cursor: pointer;
}
.productdisplay-category{
    margin-top: 10px;
}
.productdisplay-category>span{
    font-weight: 600;
}