.popular{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    height: 90vh;
}
.popular>h1{
    color: #171717;
    font-size: 50px;
    font-weight: 600;
}
.popular>hr{
    width: 200px;
    height: 0.5px;
    border: 3px solid #252525;
    border-radius: 10px;
    background: #252525;
}
.popular-item{
    margin-top: 50px;
    display: flex;
    gap: 30px;
}