.login{
    width: 100%;
    height: 100vh;
    background: #fce3fe;
    padding-top: 100px;
    margin-bottom: 100px;
}
.login-container{
    width: 580px;
    height: 600px;
    background: #fff;
    margin: auto;
    padding: 40px 60px;
}
.login-container>h1{
    margin: 20px 0;
}
.login-fields{
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 30px;
}
.login-fields>input{
    height: 72px;
    width: 100%;
    padding-left: 20px;
    border: 1px solid #c9c9c9;
    border-radius: 5px;
    outline: none;
    color: #5c5c5c;
    font-size: 18px;
}
.login-container>button{
    width: 500px;
    height: 72px;
    color: #fff;
    background: #ff4141;
    margin-top: 30px;
    border: none;
    border-radius: 5px;
    font-size: 24px;
    font-weight: 500;
    cursor: pointer;
    margin-left: 50px;
}
.login-login{
    margin-top: 20px;
    color: #5c5c5c;
    font-size: 18px;
    font-weight: 500;
}
.login-login>span{
    color: #ff4141;
    font-weight: 600;
}
.login-agree{
    display: flex;
    align-items: center;
    margin-top: 25px;
    gap: 20px;
    color: #5c5c5c;
    font-size: 18px;
    font-weight: 500;
}