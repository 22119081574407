.newsletter{
    width: 65%;
    height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 0 140px;
    margin-bottom: 150px;
    background: linear-gradient(180deg, #fde1ff 0%, #e1ffea22 60%);
    gap: 30px;
}

.newsletter>h1{
    color: #454545;
    font-size: 55px;
    font-weight: 600;
}
.newsletter>P{
    color: #454545;
    font-size: 22px;
}
.input{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    width: 730px;
    height: 70px;
    border-radius: 80px;
    border: 1px solid #e3e3e3;
}
.input>input{
    width: 500px;
    margin-left: 30px;
    border: none;
    outline: none;
    color: #616161;
    font-family: Poppins;
    font-size: 16px;
}
.input>button{
    width: 210px;
    height: 70px;
    border-radius: 80px;
    background: #000;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}