
.shop-category>img{
    display: block;
    margin: 30px auto;
    width: 82%;
    height: 350px;

}
.shopcategory-indexsort{
    display: flex;
    margin: 0 170px;
    justify-content: space-between;
    align-items: center;
}
.shopcategory-indexsort>p>span{
    font-weight: 600;
}

.shopcategory-sort{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;
    border-radius: 40px;
    border: 1px solid #888;
}
.shopcategory-sort>img{
    width: 16px;
}

.shopcategory-products{
    margin: 20px 80px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 80px;
}

.shopcategory-loadmore>button{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 150px auto;
    width: 233px;
    height: 69px;
    border-radius: 75px;
    background: #ededed;
    color: #787878;
    font-size: 18px;
    font-weight: 500;
    border: none;
    outline: none;
    cursor: pointer;
}