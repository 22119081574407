.offers{
    width: 65%;
    height: 60vh;
    display: flex;
    margin: auto;
    padding: 0px 140px;
    margin-top: 150px;
    margin-bottom: 150px;
    background: linear-gradient(180deg, #fde1ff 0%, #e1ffea22 60%);
}

.offers-left{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.offers-left>h1{
    color: #171717;
    font-size: 60px;
    font-weight: 500;
}
.offers-left>p{
    color: #171717;
    font-size: 22px;
    font-weight: 500;
}
.offers-left>button{
    width: 282px;
    height: 70px;
    border-radius: 35px;
    background-color: #ff4141;
    border: none;
    color: #fff;
    font-size: 22px;
    font-weight: 500;
    margin-top: 30px;
    cursor: pointer;
}
.offers-right{
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 50px;
}

.offers-right>img{
    height: 90%;
}