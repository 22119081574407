.description-box{
    margin: 120px 170px;
}
.navigator{
    display: flex;
}
.nav-box{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    width: 170px;
    height: 70px;
    border: 1px solid #d0d0d0;
}
.nav-box.fade{
    background-color: #fbfbfb;
    color: #555;
}
.description{
    display: flex;
    flex-direction: column;
    gap: 25px;
    border: 1px solid #d0d0d0;
    padding: 48px;
    padding-bottom: 70px;
}
.description>p{
    text-align: justify;
}